 .container {
  padding: 40px 0;
  overflow: hidden;
}

 .title {
  composes: appSubtitle from global;
  margin-bottom: 40px;
  text-align: center;
}

 .category {
  composes: content from global;
  composes: buttonShadow from global;
  display: flex;
  background-color: #111;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.category img {
  border-radius: 5px;
}

 .name {
  composes: appTitle from global;
  color: #fff;
  padding: 0 10px;
  font-size: 30px;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4), -1px -1px 10px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 2;
}

.category:hover .name {
  opacity: 1;
}

@media (max-width: 768px) {
   .name {
    opacity: 1;
  }
}
