.container {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}

.title {
  composes: appSubtitle from global;
  margin-bottom: 40px;
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}

.category {
  display: flex;
  background-color: #111;
  min-height: 250px;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;
  composes: buttonShadow from global;
  margin-bottom: 20px;
  text-align: center;
}

.name {
  padding: 10px 0;
  font-size: 30px;
  text-align: left;
  composes: appTitle from global;
}

.link {
  display: block;
  color: #111;
  padding: 10px;
  margin-bottom: 10px;
}

@media (max-width: 760px) {
  .container {
    border-radius: 5px;
    overflow: hidden;
  }
}

.categoryContainer {
  flex: 1 0 20%;
  padding: 1rem;
}